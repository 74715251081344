<template>
  <div style="margin-top: 4px;">
    <el-divider></el-divider>
    <vxe-table ref="table" :loading="loading" border show-overflow @edit-actived="editActived" @edit-closed="editClosed" :row-config="{ isHover: true }" :data="tableData" :tooltip-config="tooltipConfig" :edit-config="{trigger: 'dblclick', mode: 'cell' ,autoClear:true,beforeEditMethod: beforeEditMethod,}">
      <vxe-column field="serialNumber" :title="$t('lang.序列号')" width="85">
        <template #edit="{ row }">
          <vxe-input v-model="row.serialNumber" type="number"></vxe-input>
        </template>
      </vxe-column>
      <vxe-column field="t1" title="t1(℃)" :edit-render="{}" align="right">
        <template #edit="{ row }">
          <vxe-input v-model="row.t1" type="number"></vxe-input>
        </template>
      </vxe-column>
      <vxe-column field="t2" title="t2(℃)" :edit-render="{}" align="right">
        <template #edit="{ row }">
          <vxe-input v-model="row.t2" type="number"></vxe-input>
        </template>
      </vxe-column>
      <vxe-column field="t3" title="t3(℃)" :edit-render="{}" align="right">
        <template #edit="{ row }">
          <vxe-input v-model="row.t3" type="number"></vxe-input>
        </template>
      </vxe-column>
      <vxe-column field="t4" title="t4(℃)" :edit-render="{}" align="right">
        <template #edit="{ row }">
          <vxe-input v-model="row.t4" type="number"></vxe-input>
        </template>
      </vxe-column>
      <vxe-column field="t5" title="t5(℃)" :edit-render="{}" align="right">
        <template #edit="{ row }">
          <vxe-input v-model="row.t5" type="number"></vxe-input>
        </template>
      </vxe-column>
      <vxe-column field="t6" title="t6(℃)" :edit-render="{}" align="right">
        <template #edit="{ row }">
          <vxe-input v-model="row.t6" type="number"></vxe-input>
        </template>
      </vxe-column>
      <vxe-column field="t7" title="t7(℃)" :edit-render="{}" align="right">
        <template #edit="{ row }">
          <vxe-input v-model="row.t7" type="number"></vxe-input>
        </template>
      </vxe-column>
      <vxe-column field="t8" title="t8(℃)" :edit-render="{}" align="right">
        <template #edit="{ row }">
          <vxe-input v-model="row.t8" type="number"></vxe-input>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager :current-page.sync="page.currentPage" :page-size.sync="page.pageSize" :total="page.total" @page-change="getList" align="left" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      cellValue: 0,
      loading: false,
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      tooltipConfig: {
        enterDelay: 0,
        contentMethod: ({ column, row }) => {
          const { field } = column;
          return row[`${field}_SensorNumber`];
        },
      },
    };
  },
  methods: {
    beforeEditMethod(column) {
      const disabledColumns = [
        "t1_SensorNumber",
        "t2_SensorNumber",
        "t3_SensorNumber",
        "t4_SensorNumber",
        "t5_SensorNumber",
        "t6_SensorNumber",
        "t7_SensorNumber",
        "t8_SensorNumber",
        "serialNumber",
      ];
      if (disabledColumns.includes(column.field)) {
        return false;
      }
      return true;
    },
    getList() {
      let that = this;
      that.loading = true;
      that.axios.post("WXCW81/YSSJ_GetList", that.page).then((response) => {
        that.tableData = response.data.data.data;
        that.page.total = response.data.data.total;
        that.loading = false;
      });
    },
    editActived({ row, column }) {
      let that = this;
      that.cellValue = row[column.property];
    },
    editClosed({ row, column }) {
      let that = this;
      if (parseFloat(that.cellValue) !== parseFloat(row[column.property])) {
        that.axios
          .post("WXCW81/YSSJ_Update", {
            id: row.id,
            serialNumber: row.serialNumber,
            dataTime: row.dataTime,
            t1: (row.t1 = Math.floor(row.t1 * 10) / 10.0),
            t1_SensorNumber: row.t1_SensorNumber,
            t2: (row.t2 = Math.floor(row.t2 * 10) / 10.0),
            t2_SensorNumber: row.t2_SensorNumber,
            t3: (row.t3 = Math.floor(row.t3 * 10) / 10.0),
            t3_SensorNumber: row.t3_SensorNumber,
            t4: (row.t4 = Math.floor(row.t4 * 10) / 10.0),
            t4_SensorNumber: row.t4_SensorNumber,
            t5: (row.t5 = Math.floor(row.t5 * 10) / 10.0),
            t5_SensorNumber: row.t5_SensorNumber,
            t6: (row.t6 = Math.floor(row.t6 * 10) / 10.0),
            t6_SensorNumber: row.t6_SensorNumber,
            t7: (row.t7 = Math.floor(row.t7 * 10) / 10.0),
            t7_SensorNumber: row.t7_SensorNumber,
            t8: (row.t8 = Math.floor(row.t8 * 10) / 10.0),
            t8_SensorNumber: row.t8_SensorNumber,
            dianLiang: row.dianLiang,
            origin: row.origin,
            createTime: row.createTime,
          })
          .then((response) => {
            if (response.data.code == 101) {
              that.$message.success(that.$t("lang.修改成功"));
            }
          });
      }
    },
  },
  mounted() {
    let that = this;
    that.getList();
  },
};
</script>

<style></style>